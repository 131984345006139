@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

#time {
    width: 85px;
}

#datePickers {
    display: flex;
    flex-direction: row;
}

#datePickers input:first-child {
    margin-right: 10px;
    width: 130px;
}
