@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
header {
    display: flex;
    width: 100%;
    height: 100%;
    background: rgb(0, 255, 90);
    background: linear-gradient(180deg, rgba(0, 255, 90, 1) 0%, rgba(3, 205, 91, 1) 21%, rgba(255, 255, 255, 0) 100%);
    justify-content: center;
}

form {
    margin: 55px auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    align-self: center;
    animation-name: example;
    animation-duration: 4s;
    margin-bottom: 0;
}
form input:focus {
    border-color: #009432;
    border-width: 2px;
}
form input, #time {
    border-radius: 7px;
    margin: 12px 0;
    padding: 10px;
    background-color: #ecf0f1;
    transition: 300ms linear;
    border-color: transparent;
    outline: 0px;
}

form button:hover {
    opacity: .7;
    cursor: pointer;
}
form h3 {
    font-size: 20px;
    display: flex;
    justify-content: center;
}

h5 {
    font-size: .9em;
    margin: 0;
}


button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    vertical-align: middle;
    border-radius: 7px;
    margin: 5px 0;
    transition: .3s linear;
    background-color: #009432;
    padding: 10px;
    color: white;
    border: 0;
    outline: 0px;
		cursor:pointer;
  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #ecf0f1; */
  font-family: 'Roboto', sans-serif;
	background-image: linear-gradient(black, black),
		url(/static/media/visaointerna-1.fb878e14.jpeg);
	background-repeat: repeat;
	background-size: cover;
	background-blend-mode: saturation;
	background-position: center;
	background-color: rgba(0,0,0, 0.4);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}


main {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

h3{
   display: flex;
   justify-content: center;
}

h1{
    display: flex;
    justify-content: center;
}

p {
    display: flex;
    justify-content: center;
}







#time {
    width: 85px;
}

#datePickers {
    display: flex;
    flex-direction: row;
}

#datePickers input:first-child {
    margin-right: 10px;
    width: 130px;
}

main {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    /* background-color: #ecf0f1; */
    font-family: 'Roboto', sans-serif;
    flex-direction: column;
}
input{
    border-radius: 7px;
    margin: 12px 0;
    padding: 10px;
    background-color: #ecf0f1;
    transition: 300ms linear;
    border-color: transparent;
    outline: 0px;
    border-color: #009432;
    transition: 300ms linear;
    border-color: transparent;
    outline: 0px;
}


input:focus {
    border-color: #009432;
    border-width: 2px;
}

ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

li{
    background-color: #fff;
    border-radius: 7px;
    padding: 10px;
    margin: 12px ;
    width: 100%;
    
}

