@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

main {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    /* background-color: #ecf0f1; */
    font-family: 'Roboto', sans-serif;
    flex-direction: column;
}
input{
    border-radius: 7px;
    margin: 12px 0;
    padding: 10px;
    background-color: #ecf0f1;
    transition: 300ms linear;
    border-color: transparent;
    outline: 0px;
    border-color: #009432;
    transition: 300ms linear;
    border-color: transparent;
    outline: 0px;
}


input:focus {
    border-color: #009432;
    border-width: 2px;
}

ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

li{
    background-color: #fff;
    border-radius: 7px;
    padding: 10px;
    margin: 12px ;
    width: 100%;
    
}
